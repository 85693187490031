<template>
  <div class="main" style="margin-top: 100px;">
    <div class="container">
      <!-- Content here -->
      <h3 class="mt-5">Terms & Conditions</h3>
      <p>In order to use the Services - you must create a Hireclap.com account at www.hireclap.com</p>
      <h5 class="mt-4">Eligibility</h5>
      <p>By using the Services, you represent and warrant that: (a) all registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; (c) you are thirteen (13) years of age or older upon registration; and (d) your use of the Services does not violate any applicable law or regulation, or any other obligation (including contractual obligation) you might have towards third parties. Any Account you have created by registering on the Site may be deleted without warning if we believe that any representation and warranty you make hereunder is breached or inaccurate.</p>
      <h5 class="mt-4">Password</h5>
      <p>When you register to become a Member, you will also be asked to choose a password for your Account. You are entirely responsible for maintaining the confidentiality of your password. You agree not to use the Account or password of another Member at any time. You agree to notify us immediately if you suspect any unauthorized use of your Account or access to your password. You are solely responsible for any and all use of your Account.</p>
      <h5 class="mt-4">Term and Termination</h5>
      <p>This Agreement shall remain in full force and effect (a) while you are using the Services IN ANY FORM, if you are a Visitor or User, and (b) for the duration of your membership, if you are a Member. You may delete your Account and end your membership at any time, for any or no reason by sending your request to Customer Care or from your account ; please note that even if you delete your Account but continue to use the Services as a Visitor, your use of the Services is still subject to this Agreement. Unless Hireclap.com has terminated your Account, you can start a new membership by registering and providing new details about the account. We may terminate your membership for any or no reason at any time by ceasing to provide the Services to you. You understand that termination of this Agreement and the Account you have created with us may involve deletion of your Account information from our live databases. We will not have any liability whatsoever to you for any termination of your Account or related deletion of your information.</p>
      <h5 class="mt-4">Rejection and Non Acceptance of Registration</h5>
      <p>Hireclap.com can at any point of time reject a particular registration (Candidates, users, advertisers and partners) if the party is found to use the site for actions that hamper the reputation of Hireclap.com business.</p>
      <h5 class="mt-4">Refund Policy for Employers </h5>
      <ol>
        <li>No Cancellation and No Refunds:
          <ul>
            <li>Once an online package is purchased from Hireclap.com, cancellations are not permitted.</li>
            <li>No refunds will be issued under any circumstances after payment is made.</li>
          </ul>
        </li>
        <li>Multiple Payments:
          <ul>
            <li>If multiple payments are made for a single product, please notify us within 7 days of the transaction.</li>
            <li>Upon notification, we will process a refund for the extra amount within 7 days on a case-by-case basis.</li>
            <li>Refund requests made after 7 days of the transaction will not be considered.</li>
          </ul>
        </li>
      </ol>

      <h5 class="mt-4">Contact Us</h5>
      <p>If you have any questions about this Terms & Conditions or your dealings with our site, please Contact Us. Write to recruit@hireclap.com</p>
    </div>
  </div>
</template>
<script>
var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://hireclap.com/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Terms",
        "item": "https://www.hireclap.com/terms"
      }]
    }
export default({
  name:'terms',
  metaInfo: {
    link: [
      { rel: 'canonical', href: process.env.VUE_APP_URL+'terms' }
    ],
    script: [
      {type: 'application/ld+json', json: breadcrumbJson},
    ]
  }
})
</script>
<style scoped>
  .v-application ol, .v-application ul {
    padding-left: 24px !important;
  }
</style>